<template>
    <div>
        <card-header title="New Payment Plan Item" icon="fa-plus"/>

        <card-body>
            <plan-item-form :item="newItem"/>
        </card-body>

        <card-footer class="pt-3 pb-3 has-text-right">
            <b-button type="is-primary" size="is-small" :disabled="!newItem.type || !newItem.due_on || !newItem.title" @click="submit"><i class="fas fa-plus mr-3"></i>New Plan Item</b-button>
        </card-footer>
    </div>
</template>

<script>
    import CardHeader from '../../TIER/components/CardHeader';
    import CardFooter from "../../TIER/components/CardFooter";
    import CardBody from "../../TIER/components/CardBody";
    import TripNoteForm from "@/cards/trips/NoteForm";

    import {client as http} from '../../http_client';
    import PlanItemForm from "./PlanItemForm";

    export default {
        props: ['card', 'props'],
        components: {PlanItemForm, TripNoteForm, CardBody, CardFooter, CardHeader},
        data: function () {
            return {
                newItem: {
                    type: 'installment',
                    title: 'Payment Plan Installment',
                    quantity: 1,
                    amount: 0.00,
                    paid: 0,
                    adjust: true
                },
                question: {}
            };
        },
        methods: {
            submit() {
                this.$emit('loading', true);
                http.post('/api/payment_plans/' + this.props.planId + '/item', {plan_id: this.props.planId, ...this.newItem}, {force: true}).then(response => {
                    this.$reloadCard('payment-plan', {planId: this.props.planId});
                    this.$saveAlert(false);
                    this.$closeCard(this.card);
                }).catch(err => {
                    console.log('An error occurred.');
                    this.$emit('loading', false);
                });
            }
        },
        mounted() {
            this.$saveAlert(true, 'Are you done creating this new plan item? Unsaved changes will be lost.');
        }
    };
</script>
